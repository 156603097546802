import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import {MDXRenderer} from 'gatsby-plugin-mdx';
import moment from 'moment';
import StateMap from "../components/StateMap";
import ExpandableText from "../components/ExpandableText";
import Layout from "../components/layout";
import SEO from "../components/seo";
import statenames from "../data/state_abbrev_to_name.json";

import "../styles/styles.css";
import "../styles/redistricting-timeline.css";

const getColorFromConfidence = (confidence) => {
    switch (confidence) {
        case "High":
            return "rgba(186, 255, 41, .55)";
        case "Medium":
            return "rgba(237, 255, 134, .40)";
        case "Low":
            return "rgba(223, 41, 53, .15)";
        case "None":
        default:
            return "rgba(74, 87, 89, .1)";
    }
}

const getConfidenceDescription = (confidence) => {
    switch (confidence) {
        case "High":
            return "We're confident in this state's projected timeline";
        case "Medium":
            return "We're somewhat confident in this state's projected timeline";
        case "Low":
            return "We're not very confident in this state's projected timeline";
        case "None":
        default:
            return "We don't have a projected timeline for this state";
    }
}

const RedistrictingTimelinePage = (context) => {
    const [timelineType, setTimelineType] = useState("Congressional");
    const [sortType, setSortType] = useState("Final_Map_Deadline");
    const [currentSection, setCurrentSection] = useState();
    const sortedTimeline = context.data.allAirtable.nodes.sort((itemA, itemB) => {
        if (itemA.data[sortType] > itemB.data[sortType]) {
            return 1;
        } else if (itemA.data[sortType] === itemB.data[sortType]) {
            return 0;
        } else {
            return -1;
        }
    });
    // const sections = new Set();
    // sortedTimeline.forEach((item) => {
    //     if (item.data['Type'] !== timelineType) {
    //         return;
    //     }
    //     let sectionTitle = item.data[sortType];
    //     if (sortType === "Final_Map_Deadline") {
    //         sectionTitle = moment(sectionTitle).format("MMM YYYY");
    //     }
    //     sections.add(sectionTitle);
    // });
    let prevMonth; 
    return (
        <Layout>
            <SEO title="Redistricting Timeline" />
            <div className="page-container">
                <h1>
                    <b>NOT FINAL:</b>
                    Projected 2021 Redistricting Timeline
                </h1>
                <div className="redistricting-container">
                <div className="redistricting-sort-filters">
                    <div>
                        <div>Redistricting type:</div>
                        <button 
                            onClick={() => setTimelineType('Congressional')}
                            className={timelineType === 'Congressional' ? 'selected' : ''}
                        >
                            Congressional
                        </button>
                        <button 
                            onClick={() => setTimelineType('State Legislative')}
                            className={timelineType === 'State Legislative' ? 'selected' : ''}
                        >
                            State Legislative
                        </button>
                    </div>
                    <div>
                        <div>Sort by:</div>
                        <button 
                            onClick={() => setSortType('Final_Map_Deadline')}
                            className={sortType === 'Final_Map_Deadline' ? 'selected' : ''}
                        >
                            Final map deadline
                        </button>
                        <button 
                            onClick={() => setSortType('State__from_Reforms_')}
                            className={sortType === 'State__from_Reforms_' ? 'selected' : ''}
                        >
                            Alphabetical
                        </button>
                    </div>
                </div>
                <div className="redistricting-timeline-container">
                    {sortedTimeline.map((item, index) => {
                        if (item.data['Type'] !== timelineType) {
                            return;
                        }
                        const currentMonth = moment(item.data['Final_Map_Deadline']).format("MMM");
                        const currentYear = moment(item.data['Final_Map_Deadline']).format("YYYY");
                        const spacer = <div className="new-row" id={`#${currentMonth}-${currentYear}`}>
                            {currentMonth} {currentYear}
                        </div>;
                        let output;
                        output = <div key={index} className="redistricting-timeline-item"
                        style={{
                            backgroundColor: getColorFromConfidence(item.data['Confidence'])
                        }}>
                            <section className="data">
                                <StateMap stateName={item.data['State__from_Reforms_'][0]}/>
                                <span className="item-name">
                                    {statenames[item.data['State__from_Reforms_'][0]]}
                                </span>
                                <div className="item-info">
                                    <div className="info-container">
                                        <div className="info-label">Confidence in accuracy</div>
                                        <div className="info-value">
                                            {item.data['Confidence']}
                                        </div>
                                    </div>
                                    <div className="info-container">
                                        <div className="info-label">Public hearings start</div>
                                        <div className="info-value">
                                            {item.data['Public_Hearings_Start'] ? 
                                                moment(item.data['Public_Hearings_Start']).format("MMM D YYYY")
                                                : '—'
                                            }
                                        </div>
                                    </div>
                                    <div className="info-container">
                                        <div className="info-label">Public hearings end</div>
                                        <div className="info-value">
                                            {item.data['Public_Hearings_End'] ? 
                                                moment(item.data['Public_Hearings_End']).format("MMM D YYYY")
                                                : '—'
                                            }
                                        </div>
                                    </div>
                                    <div className="info-container">
                                        <div className="info-label">Draft map release</div>
                                        <div className="info-value">
                                            {item.data['Draft_Map_Release'] ? 
                                                moment(item.data['Draft_Map_Release']).format("MMM D YYYY")
                                                : '—'
                                            }
                                        </div>
                                    </div>
                                    <div className="info-container">
                                        <div className="info-label">Final map deadline</div>
                                        <div className="info-value">
                                            {item.data['Final_Map_Deadline'] ? 
                                                moment(item.data['Final_Map_Deadline']).format("MMM D YYYY")
                                                : '—'
                                            }
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="expanded-info">
                                <ExpandableText collapsedDefault={true}>
                                    <div>
                                        {item.data["_2011_Cycle_Dates"].internal.content}
                                        {/* <MDXRenderer>{item.data["Methodology"].childMdx.body}</MDXRenderer> */}
                                        {/* <MDXRenderer>{item.data["Public_Input_Notes"].childMdx.body}</MDXRenderer> */}
                                        {/* <MDXRenderer>{item.data["Possible_Effects_of_Census_Delay"].childMdx.body}</MDXRenderer> */}
                                    </div>
                                </ExpandableText>
                            </section>
                        </div>;
                        if (sortType === "Final_Map_Deadline"
                            && ((prevMonth && prevMonth !== currentMonth)
                                || (!prevMonth))
                            ) {
                            prevMonth = currentMonth;
                            return [spacer, output];
                        } else {
                            prevMonth = currentMonth;
                            return output;
                        }                       
                    })}
                </div>
                </div>
            </div>
        </Layout>
    );
};

export default RedistrictingTimelinePage;


export const redistrictingTimelineQuery = graphql`
  query redistrictingTimelineQuery {
    allAirtable(filter: {table: {eq: "Redistricting Timeline"}}) {
        nodes {
          id
          data {
            RTName
            RTState
            State__from_Reforms_
            Type
            Public_Hearings_Start
            Public_Hearings_End
            Draft_Map_Release
            Final_Map_Deadline
            Confidence
            Methodology {
              childMdx {
                body
              }
            }
            Public_Input_Notes {
              internal {
                content
              }
              childMdx {
                body
              }
            }
            Possible_Effects_of_Census_Delay {
              internal {
                content
              }
              childMdx {
                body
              }
            }
            _2011_Cycle_Dates {
              internal {
                content
              }
              childMdx {
                body
              }
            }
          }
        }
      }
  }
`;